module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"sampleRate":1},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/buildhome/repo/src/intl","languages":["en-US","sv-SE"],"defaultLanguage":"en-US","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Qouldnt","short_name":"Qouldnt","description":"Services around places you care about.","lang":"en","display":"minimal-ui","icon":"./node_modules/@qouldnt/favicon/dist/favicon.png","start_url":"/en-US/","background_color":"#00b2d2","theme_color":"#00B2D2","localize":[{"start_url":"/sv-SE/","lang":"sv","name":"Qouldnt","short_name":"Qouldnt","description":"Tjänster kring platser du bryr dig om."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7c4edffcde4213bcdbd27ca35bd3b009"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"qouldnt.com","customDomain":"analytics.qouldnt.net"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
